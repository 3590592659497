import { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import defaultSocketEvents from '@context/defaultEvents'
import { isJson } from '@utils'

const WsContext = createContext()
let wsEvents = []
let wsBody = null
const addWebSocketEvent = event => {
  wsEvents = [...wsEvents.filter(item => item.type !== event.type), event]
}

const WebSocketProvider = ({ children }) => {
  //события соккетов статические
  const { eventLesson } = defaultSocketEvents()
  const { userData } = useSelector(state => state.auth)

  useEffect(() => {
    let myWebSocket = null
    if (userData) {
      myWebSocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost')
      myWebSocket.onopen = () => {
        console.log('Websocket start')
        if (!wsBody) {
          wsBody = myWebSocket
        }
      }
      myWebSocket.onclose = e => {
        if (e.wasClean) {
          console.log('Websocket close')
        } else {
          console.log('Websocket closet with error')
        }
      }
      myWebSocket.onmessage = ev => {
        //запустить эвенты повешаные на события сокетов
        if (isJson(ev.data)) {
          //эвент начала урока и события подписаные на этот эвент
          eventLesson(ev.data, [...wsEvents.filter(item => /start_meeting_\d{1,}$/.test(item.type))])
        }
      }
      myWebSocket.onerror = () => {
        console.log('Websocket error')
      }
    }

    return () => {
      if (myWebSocket) {
        myWebSocket.close()
      }
      myWebSocket = null
    }
  }, [userData])

  return (
    <WsContext.Provider value={addWebSocketEvent}>
      {children}
    </WsContext.Provider>
  )
}

export { WebSocketProvider, WsContext, wsBody }