import faqState from "../reducers/FAQ/initialState"
import {getObjectFromLocalstorage} from '@utils'

const userData = getObjectFromLocalstorage('userData')
const userRole = getObjectFromLocalstorage('userRole')
const userPersonalData = getObjectFromLocalstorage('userPersonalData')

//защита локальных данных
if (
    (userData || userRole || userPersonalData) &&
    !(
        (userData && userData.roles && userData.token) &&
        (userRole && ['choose', 'teacher', 'student'].includes(userRole)) &&
        (userPersonalData && userPersonalData.name)
    )
) {
  localStorage.removeItem('userData')
  localStorage.removeItem('userRole')
  localStorage.removeItem('userPersonalData')
  location.href = '/'
}

const defaultStates = {
  auth: {
    userData,
    userRole,
    userPersonalData
  },
  faq: {
    ...faqState,
    hidenItems: getObjectFromLocalstorage('faqItems') ?? []
  }
}

export default defaultStates