import {useSelector} from 'react-redux'

export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// вытащить толко числа из строки
export const getOnlyNumbers = txt => {
  const numb = txt?.match(/\d/g)
  return numb?.join("")
}

//совпадение ид узера
export const matchUserID = (id, data) => {
  const ids = Array.isArray(data) ? data : data.split(',')
  if (ids.length) {
    return ids.some(check => id === parseInt(check))
  } else {
    return id === parseInt(data)
  }
}

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {month: 'short', day: 'numeric', year: 'numeric'}) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = {month: 'short', day: 'numeric'}

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = {hour: 'numeric', minute: 'numeric'}
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getUserRole = () => localStorage.getItem('userRole')

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const Variables = {
  weeks: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']
}

export const isJson = str => {
  try {
    JSON.parse(str)
  } catch {
    return false
  }
  return true
}

export const setObjectToLocalstorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const getObjectFromLocalstorage = key => {
  const data = localStorage.getItem(key)
  return isJson(data) ? JSON.parse(data) : data
}

export const DefaultModal = () => {
  const content = useSelector(state => state.modal.content)
  return (
    <>{content}</>
  )
}

export const declOfNum = (number, words) => {
  return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]]
}

export const AvatarGroupFields = (items, title = '', slice = 7) => {
  const students = items.length > slice ? items.slice(0, slice) : items
  return [
    ...students.map(item => {
      return {
        title: item.name,
        placement: 'bottom',
        img: item.avatar ? item.avatar : require('@src/assets/images/avatars/avatar-blank.png').default,
        imgHeight: 33,
        imgWidth: 33,
        itemID: item.id
      }
    }), {
      meta: items.length,
      group_title: title
    }
  ]
}

export const idGen = () => 'abcdefghijklmnopqrstuvwxyz'.split('').map((i, j, a) => a[Math.floor(Math.random() * 25)]).join('')

export const costNormalize = (value, currency = "RUB") => {
  const formats = {
    RUB: "ru-RU",
    KZT: "ru-KZ"
  }

  if (!value && value !== 0) return ''

  return new Intl.NumberFormat(formats[currency], {
    style: "currency",
    currency,
    minimumFractionDigits: 0
  }).format(value)
}

export function getCookie(name) {
  /*eslint-disable */
  const matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ))
  /*eslint-enable */
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export function setCookie(name, value, options = {}) {

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  if ('weeks' in options) {
    const now = new Date()
    const time = now.getTime()
    const expireTime = time + (168 * options.weeks * 3_600_000)
    now.setTime(expireTime)
    options.expires = now.toUTCString()
    delete options.weeks
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  /*eslint-disable */
  for (const optionKey in options) {
    updatedCookie += "; " + optionKey
    const optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue
    }
  }
  /*eslint-enable */

  document.cookie = updatedCookie
}

export function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}

export function checkBrowser() {
  const userAgent = navigator.userAgent
  let tem = userAgent.match(/(opera|yabrowser|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  let match = tem

  if (/trident/i.test(match[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || []
    return {name: 'IE', version: (tem[1] || '')}
  }

  if (match[1] === 'Chrome') {
    tem = userAgent.match(/\b(OPR|Edg|YaBrowser)\/(\d+)/)
    if (tem !== null) {
      return {name: tem[1].replace('OPR', 'Opera'), version: tem[2]}
    }
  }

  match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?']

  if ((tem = userAgent.match(/version\/(\d+)/i)) !== null) {
    match.splice(1, 1, tem[1])
  }

  return {name: match[0], version: match[1]}
}

export function getOS() {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

export async function enableNotifications(server) {
  const permission = await Notification.requestPermission()
  const sw = await navigator.serviceWorker.getRegistration('./service-workers/notification-service-worker.js')

  if (permission === 'granted') {
    const data = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.REACT_APP_WEB_PUSH_KEY
    })
    server.post('/web-push/subscribe', {data, device_id: null, permission})
  } else if (permission === 'denied') {
    server.post('/web-push/subscribe', {data: {}, device_id: null, permission})
    localStorage.setItem('subscribe', 'no')
  }
}