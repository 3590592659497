
const initialState = {
  items: [],
  isLoading: false,
  error: false
}

const CurrentLessonsTeacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'current.lessons.teacher/set.is.loading':
      return { ...state, isLoading: action.data }
    case 'current.lessons.teacher/set.items':
      return { ...state, items: action.data }
    case 'current.lessons.teacher/set.error':
      return { ...state, error: action.data }
    default:
      return state
  }
}

export default CurrentLessonsTeacherReducer