import useOnServer from '@hooks/useOnServer'
import { getOnlyNumbers, isJson, matchUserID } from '@utils'
import defaultModalActions from '@store/actions/defaultModal'
import { useSelector } from 'react-redux'
import LessonModal from './../../views/pages/student/home/nearest-lesson/LessonModal'
import SystemCanceledLessonModal from "../../views/ui-elements/Dialogs/SystemCanceledLessonModal"
import * as moment from 'moment'
import 'moment/locale/ru'

const defaultSocketEvents = () => {
  const $api = useOnServer()
  const userPersonalData = useSelector(state => state.auth.userPersonalData)
  const { setDefaultModalContent } = defaultModalActions()

  return {
    //эветн на начало урока ev - данные с сокета, events - подписчики на данное событие
    eventLesson: (ev, events) => {
      if (isJson(ev)) {
        const data = JSON.parse(ev)
        const { lesson_id, student_ids, lesson_time } = data
        const lessonID = getOnlyNumbers(lesson_id)

        if (userPersonalData && matchUserID(userPersonalData.id, student_ids)) {

          // Урок начался
          if (data.action === 'start_meeting') {
            //подождем 2 секунды после нажатия на "начать урок"
            setTimeout(() => {
              $api.get(`meeting/details/${lessonID}`)
                .then(resp => {
                  const { url } = resp.data
                  setDefaultModalContent(<LessonModal
                    date={data.lesson_time}
                    openURL={url} />)
                  //запускаем событие по id от пришедшего сокет события.
                  events.map(item => {
                    //отработать только на текущий lesson_id
                    if (item.type === `start_meeting_${lessonID}`) {
                      item.eventRun(url)
                    }
                  })
                })
                .catch((err) => console.log('error', err.response.data))
            }, 2000)
            // Урок отменился автоматически если нет оплаты
          } else if (data.action === 'systemCanceledLesson') {
            setDefaultModalContent(<SystemCanceledLessonModal date_lesson={lesson_time} />)
          }
        }
      }
    }
  }
}

export default defaultSocketEvents