import axios from 'axios'
import onServerConfig from '@configs/serverConfig'
import { useDispatch, useSelector } from 'react-redux'
import { handleLogout } from '@store/actions/auth'
import { useHistory } from 'react-router-dom'
import {toast} from "react-toastify"

function findErrorText(object) {
  if (typeof object === "string") return object
  for (const i in object) {
    if (object[i] !== null && typeof object[i] === 'object') {
      return findErrorText(object[i])
    } else if (typeof object[i] === 'string') {
      return object[i]
    }
  }
  return null
}

const onServerFunc = () => {

  const userData = useSelector(state => state.auth.userData)

  const ser = axios.create({
    baseURL: onServerConfig.domen
  })

  const nav = useHistory()
  const dispatch = useDispatch()

  ser.interceptors.request.use(
    config => {
      const accessToken = userData?.token
      if (accessToken) {
        config.headers.Authorization = `${onServerConfig.tokenType} ${accessToken}`
      }
      config.headers.Accept = 'application/json'
      return config
    },
    error => Promise.reject(error)
  )

  ser.interceptors.response.use(
    response => response,
    error => {
      const { response: { status = null } = { status: null } } = error
      if (status === 401) {
        nav.push('login')
        dispatch(handleLogout())
      } else {
        toast.error(findErrorText(error.response?.data?.errors || error.response?.data?.error))
      }
      return Promise.reject(error)
    }
  )

  return ser
}

export default onServerFunc