// **  Initial State
const initialState = {
  userData: null,
  userRole: null,
  userPersonalData: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROLE':
      return {
        ...state,
        userRole: action.data
      }
    case 'auth/user/set.personal.data':
      return {
        ...state,
        userPersonalData: action.data
      }
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        userRole: action.userRole
      }
    case 'LOGOUT':
      return { ...state, userData: null, userRole: false }
    default:
      return state
  }
}

export default authReducer
