
const initialState = {
  item: null,
  items: [],
  archiveItems: [],
  activeItems: [],
  isLoading: false,
  error: null
}

const advertReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'advert/set.error':
      return { ...state, error: action.data }
    case 'advert/set.is.loading':
      return { ...state, isLoading: action.data }
    case 'advert/set.item':
      return { ...state, item: action.data }
    case 'advert/set.items':
      return { ...state, items: action.data }
    case 'advert/set.archive.items':
      return { ...state, archiveItems: action.data }
    case 'advert/set.active.items':
      return { ...state, activeItems: action.data }
    default:
      return state
  }
}
export default advertReducer