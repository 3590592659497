// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import "react-datepicker/dist/react-datepicker.css"
// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'

// Also includes custom styles from src/assets/scss
import './@core/scss/core.scss'

import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import SpinnerWrapper from './views/ui-elements/spinners/SpinnerWrapper'
import { WebSocketProvider } from '@src/utility/context/SocketContext'
import { ViewportProvider } from '@context/ViewportContext'
// ** Lazy load app

const LazyApp = lazy(() => import('./App'))
ReactDOM.render(

  <Provider store={store}>
    <WebSocketProvider>
      <Suspense fallback={<Spinner />}>
        <ViewportProvider>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
            <SpinnerWrapper />
          </ThemeContext>
        </ViewportProvider>
      </Suspense>
    </WebSocketProvider>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()