const initialState = {
  items: [],
  isLoading: false,
  error: false
}

const ActiveLessonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'active.lesson/set.is.loading':
      return {...state, isLoading: action.data}
    case 'active.lesson/set.items':
      return {...state, items: action.data}
    case 'active.lesson/set.is.open':
      const changeItem = state.items.map(i => {
        return i.id === action.data.id ? {...i, isOpen: action.data.isOpen} : {...i, isOpen: false}
      })
      return {...state, items: [...changeItem]}
    case 'active.lesson/set.error':
      return {...state, error: action.data}
    default:
      return state
  }
}

export default ActiveLessonsReducer