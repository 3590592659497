
import { createContext, useEffect, useState, useContext } from 'react'

const ViewportContext = createContext({})

const useViewport = () => useContext(ViewportContext)


const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const setViewport = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener('resize', setViewport)
    return () => window.removeEventListener('resize', setViewport)
  }, [])

  return (
    <ViewportContext.Provider value={{ width, height }}>
      {children}
    </ViewportContext.Provider>
  )
}

export {
  useViewport,
  ViewportProvider
}