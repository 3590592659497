import initialState from "./initialState"


const frequentlyAskedQuestions = (state = initialState, action) => {
  switch (action.type) {
    case 'faq/set.is.loading':
      return { ...state, isLoading: action.data }
    case 'faq/set.items':
      return { ...state, items: action.data }
    case 'faq/set.hiden.items':
      return { ...state, hidenItems: action.data }
    default:
      return state
  }
}

export default frequentlyAskedQuestions