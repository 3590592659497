// ** UseJWT import to get config
import onServerConfig from '@configs/serverConfig'

const config = onServerConfig

export const handleUserRole = data => dispatch => {
  dispatch({type: 'SET_ROLE', data})
  localStorage.setItem('userRole', data)
}

export const handleUserPersonalData = data => dispatch => {
  dispatch({type: 'auth/user/set.personal.data', data})
  localStorage.setItem('userPersonalData', JSON.stringify(data))
}

// ** Handle User Login
export const handleLogin = data => {
  //если ролей больше одной, то предоставить выбор
  const role = data.roles.length > 1 ? 'choose' : data.roles[0]
  return dispatch => {
    dispatch({
      type: 'LOGIN',
      data,
      config,
      userRole: role
    })

    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify(data))
    localStorage.setItem('userRole', role)
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return dispatch => {
    dispatch({ type: 'LOGOUT' })

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('userRole')
    localStorage.removeItem('userPersonalData')
  }
}
