
const initialState = {
  items: [],
  isLoading: false,
  error: null,
  limit: 20,
  date: null,
  page: null
}

const TeacherLessonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'teacher.all.lessons/set.error':
      return { ...state, error: action.data }
    case 'teacher.all.lessons/set.is.loading':
      return { ...state, isLoading: action.data }
    case 'teacher.all.lessons/set.items':
      return { ...state, items: action.data }
    case 'teacher.all.lessons/set.limit':
      return { ...state, limit: action.data }
    case 'teacher.all.lessons/set.date':
      return { ...state, date: action.data }
    case 'teacher.all.lessons/set.page':
      return { ...state, page: action.data }
    default:
      return state
  }
}
export default TeacherLessonsReducer