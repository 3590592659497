// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import layout from './layout'
import navbar from './navbar'
import nearestLesson from './NearestLesson'
import spinner from './spinner'
import modal from './defaultModal'
import allLessons from './AllLessons'
import faq from './FAQ'
import advert from './advert'
import pastLesson from './pastLessons'
import payment from './payments'
import activeLessons from './activeLessons'
import calendar from './calendar'
import teacherCurrentLessons from './currentLessonTeacher'
import teacherLessons from './TeacherLessons'
import teacherWelcomes from './TeacherWelcomes'
import teacherTimes from './teacherTimes'
import serverTime from "./ServerTime"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  spinner,
  modal,
  allLessons,
  faq,
  advert,
  nearestLesson,
  pastLesson,
  payment,
  activeLessons,
  calendar,
  teacherCurrentLessons,
  teacherLessons,
  teacherTimes,
  serverTime,
  teacherWelcomes
})

export default rootReducer
