const initialState = {
  servTime: null
}

const serverTime = (state = initialState, action) => {
  switch (action.type) {
    case 'server_time/set':
      return { servTime: action.data }
    default:
      return state
  }
}
export default serverTime