import {Button, Card, CardBody, CardGroup, CardText, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import {FormattedMessage, useIntl} from "react-intl"
import defaultModalActions from "@store/actions/defaultModal"

const SystemCanceledLessonModal = ({date_lesson}) => {
  const {closeDefaultModal} = defaultModalActions()
  const intl = useIntl()

  const onClose = () => {
    window.location.href = '/'
    closeDefaultModal()
  }

  return (
    <Modal isOpen={true} style={{maxWidth: 460}}>
      <ModalHeader tag='h3' className='p-2 d-flex justify-content-center'>
        <FormattedMessage id='title_lesson_canceled'/>
      </ModalHeader>
      <ModalBody>
        <CardGroup>
          <CardText tag="div">
            {
              intl.formatMessage({id: 'txt_automatically_canceled_lesson'}, {date_lesson}).split('\n').map(
                (i, index) => <p key={index}>{i}</p>
              )
            }
          </CardText>
        </CardGroup>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' className='apply' onClick={onClose}><FormattedMessage id='btn_cancel'/></Button>
      </ModalFooter>
    </Modal>
  )
}

export default SystemCanceledLessonModal
