
const initialState = {
  content: false
}

const DefaultModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'modal/set.content':
      return { ...state, content: action.data }
    default:
      return state
  }
}

export default DefaultModalReducer