import { lazy } from "react"
import { useSelector } from "react-redux"

const SpinnerWrapper = () => {

    const Spinner = lazy(() => import('@src/@core/components/spinner/Fallback-spinner'))
    const showSpinner = useSelector(state => state.spinner.isShow)
    return (
        showSpinner && <div className='spinner-wrapper'><Spinner /></div>
    )
}

export default SpinnerWrapper