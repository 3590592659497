
const initialState = {
  items: [],
  isLoading: false
}

const TeacherWelcomesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'teacher.welcomes/set.is.loading':
      return { ...state, isLoading: action.data }
    case 'teacher.welcomes/set.items':
      return { ...state, items: action.data}
    default:
      return state
  }
}
export default TeacherWelcomesReducer