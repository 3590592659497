import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"

const setDefaultModalContent = data => dispatch => dispatch({ type: 'modal/set.content', data })

const closeDefaultModal = () => dispatch => dispatch({ type: 'modal/set.content', data: false })

const defaultModalActions = () => {
  const dispatch = useDispatch()

  return bindActionCreators({
    setDefaultModalContent,
    closeDefaultModal
  }, dispatch)
}

export default defaultModalActions