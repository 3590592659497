
const initialState = {
  isLoading: false,
  items: [],
  error: false,
  limit: 10
}

const AllLessons = (state = initialState, { type, data }) => {
  switch (type) {
    case 'all.lessons/set.is.loading':
      return { ...state, isLoading: data }
    case 'all.lessons/set.items':
      return { ...state, items: data }
    case 'all.lessons/set.error':
      return { ...state, error: data }
    case 'all.lessons/set.limit':
      return { ...state, limit: data }
    default:
      return state
  }
}

export default AllLessons