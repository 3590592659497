const initialState = {
  payment: null,
  items: [],
  isLoading: false,
  error: null
}

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'payment/set.error':
      return { ...state, error: action.data }
    case 'payment/set.is.loading':
      return { ...state, isLoading: action.data }
    case 'payment/set.items':
      return { ...state, items: action.data }
    case 'payment/set.payment':
      return { ...state, payment: action.data }
    default:
      return state
  }
}
export default paymentReducer